//Hopup
$closeBtnColor: #999995 !default;
$closeBtnColorHover: darken(get-color(secondary), 60%) !default;
$closeBtnFontSize: 22px !default;
$hopupProductPriceColor: $brandColorPalette1 !default;
$altViewColorBorder: #F1F1F1 !default;
$altViewsHeight: 80px !default;
$productImgZoomWidth: 1280px !default;
$productImgZoomHeight: 750px !default;

//PDP Hopup Viewer
.hopup-viewer-container {
	.product-info {
		margin: 40px 60px 0 60px;
	}

	.product-price {
		color: $hopupProductPriceColor;
		min-width: 300px;
		display: block;
	}

	.main-view {
		position: relative;

		& > div {
			width: 10%;
		}

		& > div:hover {
			cursor: pointer;
		}

		.prev-view-btn,
		.next-view-btn {
			display: inline;
			background: transparent;
		}

    	.next-view-btn {
			float: right;
    	}

		figure {
			width: 80%;
			margin: 0 10%;

			img {
				width: 100%;
			}
		}
	}

	.alt-views {
		clear: both;
		position: relative;
		height: $altViewsHeight;
		margin: 0 10% 40px;
	}

	figure {
		margin: 0 20px 4px 0;
		border: $facetBoxBorderThickness solid transparent;
		display: inline-block;

		&.attr-box {
			&.selected {
				border: $facetBoxBorderThickness solid $borderFocusColor;
			}

			&:hover {
				border: $facetBoxBorderThickness solid $facetBoxHoverColor;
			}
		}
	}
}

//Exit Exchange Message Hopup
$product-exchange-message-exit-section-padding-large: 20px 60px 0 30px !default;
$product-exchange-message-exit-p-font-family: $header-font-family !default;
$product-exchange-message-exit-p-font-weight: $header-font-weight !default;
$product-exchange-message-exit-p-font-size: em-calc(header-font-size(h4, medium)) !default;
$product-exchange-message-exit-section-padding: 20px 0 0 !default;
$product-exchange-message-exit-cta-buttons-margin: 0 0 10px 0 !default;

$productExchangeEnabled: false !default;

/////////////////S T A R T/////////////////
@if($productExchangeEnabled) {
//Exit Exchange Message
	.product-exchange-message-exit {
		section {
			padding: $product-exchange-message-exit-section-padding;
		}

		p {
			font-family: $product-exchange-message-exit-p-font-family;
			font-size: $product-exchange-message-exit-p-font-size;
			font-weight: $product-exchange-message-exit-p-font-weight;
		}

		.product-exchange-message-exit-cta-primary,
		.product-exchange-message-exit-cta-secondary {
			margin: $product-exchange-message-exit-cta-buttons-margin;
			text-align: center;
			width: 100%;
		}

		@include breakpoint(large) {
			section {
				padding: $product-exchange-message-exit-section-padding-large;
			}

			.product-exchange-message-exit-cta-primary,
			.product-exchange-message-exit-cta-secondary {
				width: auto;
				margin: auto;
			}

			.button {
				width: auto;
			}
		}
	}
}
/////////////////E N D/////////////////
