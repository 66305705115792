.single-pass-input {
  &-wrapper {
    position: relative;
  }

  &-show-toggle {
    font-style: italic;
    opacity: 0;
    padding: 0.9em;
    position: absolute;
    right: 0;
    top: 0;

    &:hover {
      cursor: pointer;
    }
  }
}
