// ==========================================================================
// print.scss
// Print Styles
// ==========================================================================

@media print {
    * {
        box-shadow: none;
        color: #000;
        filter: none;
        font-family: sans-serif;
        text-shadow: none;
    }

    // Black prints faster: h5bp.com/s
    a,
    a:visited {
        text-decoration: none;
    }

    // Don't show links for images, or javascript/internal links
    pre,
    blockquote {
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    // h5bp.com/t
    tr,
    img {
        page-break-inside: avoid;
    }

    p,
    table,
    th,
    td,
    ul,
    ol,
    dl,
    blockquote,
    pre,
    code,
    form,
    h1,
    h2,
    h3,
    h4,
    h5 {
        color: black;
    }

    p {
        margin: auto;
    }

    img {
        left: 0;
        margin: 0;
        position: static;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    header {
        .util,
        .responsive {
            display: none;
        }
    }

    .global-header {
        max-width: 100%;
        width: 100%;
    }

    .page-util {
        display: none;
    }

    #store-logo {
        height: 76px;
    }

    #cd-pagination-wrap,
    #cd-gutter,
    #cd-quickshop,
    #cd-sidebar,
    #cd-related-wrap,
    footer,
    .back-to-link {
        display: none;
    }

    #body-container {
        #cd-main-wrap {
            width: 100%;
            max-width: 100%;
            margin: 0;
        }

        #cd-content-wrap {
            padding: 0;
            float: none;
            border: 0;
            width: 100%;
        }

        .cd-content-extend {
            max-width: 100%;
            width: 100%;
            overflow: auto;
            left: 0;
            position: static;
            margin: 0;
        }

        #cd-content {
            padding: 0;
        }
    }

    a[href]:after {
        content: "";
    }

    .breadcrumb-bar {
        display: none;
    }

    .curation-container {
        .curation-more-section {
            display: block;
        }

        hr,
        .more-athletes-button {
            display: none;
        }
    }

    footer.extended {
        display: none;
    }

    .mini-cart-section,
    .print-preview {
        display: none;
    }

    .order-detail-info {
    	h6 {
    		margin-bottom: 10px;
    	}
    }

    // START VFDP-828 Vans-17253086 - Some formatting fixes for printing receipts at Vans stores.
    .page-title {
    	font-size: 1.5em;
    	// margin-bottom: 20px;
    }

    .checkout-summary-label {
    	font-size: 1em;
    }

    .order-item-summary h6,
    .order-summary-info h6,
    .checkout-items-list h6 {
    	font-size: 1.5em;
    }

    .item-name-js {
    	font-size: 1.25em;
    }

    .summary-info-title {
    	font-size: 1.5em;
    }

    .order-date {
    	font-size: 1em;
    }
    // END VFDP-828 Vans-17253086
}

