.user-timeout {
	.page-contents {
		@include grid-row;
		text-align: center;
		padding: {
			top: 20px;
			bottom: 40px;
		}

		h1 {
			font-size: em-calc(24);
		}

		div {
			margin: 15px 0;
		}
	}
}
