@import 'widgets/tooltipster';

.tooltipster {

	&-default,
	&-base,
	&-common,
	&-shadow {
		background: $white;
		border: 1px solid get-color(nobel);
		border-radius: 0;
		box-shadow: none;
		font-size: $global-font-size;
		color: $black;
		width: 49%;

		.tooltipster-content {
			background-color: $white;
			color: $black;
			font-size: em-calc(10);
			padding: 15px;
			width: 100%;

			@include breakpoint(medium) {
	    		padding: 18px;
	    	}
		}

		.tooltipCloseBtn {
			display: none;
		}

		@include breakpoint(medium) {
	    	width: 30%;
	    }

		.tooltipster-arrow {
			&-border {
				border-color: get-color(nobel);
			}
		}
	}

	&-pdp-swatch {
		border: 2px solid get-color(burnt-orange);
		width: auto;

		.tooltipster-content {
			color: get-color(burnt-orange);
			font-size: em-calc(12);
			padding: 0;

			.icon-error {
				display: none;
			}

			.error-message {
				height: auto;
				margin: 0 8px;
			}
		}
	}
}

