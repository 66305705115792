//
// Provides a arrow button so user can click to jump back
// to the top of the page. Arrow is not initially visible
// until user scrolls and Javascript shows/hides the arrow
//
$scrolltotop-color: $black !default;
$scrolltotop-size: 41px !default;
$scrolltotop-image: (
				image: url('../images/common/back-to-top.png'),
				repeat: 'no-repeat'
) !default;
$scrolltotop-icon: $icon-arrow-up-indicator-open !default;
$scrolltotop-button-background-color: transparent !default;
$scrolltotop-button-background-color-hover: transparent !default;
$scrolltotop-button-padding: 0 !default;


@mixin scrolltotop-button($display-type, $asset, $size: $scrolltotop-size, $color: $scrolltotop-color) {
	@if $display-type {
		@include z-index(fixedbuttons);

		background-color: $scrolltotop-button-background-color;
		bottom: 35px;
		display: block;
		height: $size;
		opacity: 0.4;
		position: fixed;
		padding: $scrolltotop-button-padding;
		right: 25px;
		transition: opacity 0.25s;
		width: $size;

		// Normal .hide visibility is hijacked here so that we can fade in/out with CSS
		&.hide {
			display: block;
			opacity: 0;
		}

		&:hover {
			background-color: $scrolltotop-button-background-color-hover;
			opacity: 0.8;
		}

		@if $display-type == image {
			background: {
				@each $key, $value in $asset {
					#{$key}: #{$value};
				}
			}
		} @else if $display-type == icon {
			color: $color;
			font-size: $size;
			line-height: $size;
			text-align: center;
			text-decoration: none;

			&:hover {
				color: $color;
			}

			&:after {
				@include icomoonFont($asset);
			}
		}
	}
}

@mixin scroll-to-top() {
	.scroll-to-top {
		@include scrolltotop-button(image, $scrolltotop-image, $scrolltotop-size);
	}
}

@mixin scroll-to-top-with-icon() {
	.scroll-to-top {
		@include scrolltotop-button(icon, $scrolltotop-icon, $scrolltotop-size, $scrolltotop-color);
	}
}


